.scoreContainerFather{
    width: 73px;
    height: 336px;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    position: relative;
    right:  -6px;
    bottom: 20px;
}
.scoreContainer{
    height: 82px;
    width: 34%;
    transform: rotate(90deg);
    display: flex;
    flex-direction: column-reverse;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    
}
.progress-intial{
    background: transparent;
    height: 10px !important;
    width: 336px;
    height: 20% !important;
    display: flex !important;
    flex-direction: column-reverse;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-content: baseline;
    overflow: visible !important;
    --bs-progress-bg: transparent !important;
    --bs-progress-bar-bg: transparent !important;

}
.score-bar{
    height: fit-content;
    width: fit-content;
    transform: rotate(-90deg);
    font-size: 12px;
}
.progress-intial .progress-bar{
    transition: width 2s ease-in-out;
    width: 25px;
}
.one .progress-bar{
    background-color: #722ED1;
}
.two .progress-bar{
    background-color: #14C9C9;
}
.three .progress-bar{
    background-color: #165DFF;
    
}
.four .progress-bar{
    background-color: #F7BA1E;
    ;
}
.typeRectangle{
    position: relative;
    bottom: -56%;
    right: 26%;
}