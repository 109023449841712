/* ModelPage.css */

.model-table-container th,
.model-table-container td {
  vertical-align: middle;
  text-align: center;
  background: #161523;
}

.model-table-container th {
  font-weight: bold;
}

.model-table-container {
    align-items: center;
    overflow-x: auto;
    margin-right: 50px;
    margin-left: 50px;
    max-height: 80vh;
    width: 98%;
    overflow-y: auto;
    display: block;
    max-width: 100% !important;
}
.model-table-container-div{
    margin-top: 42px;
    margin-left: 50px;

}

.container-field-auto > div {
  padding-right: 0px !important;
}
