.login{
    background-image: url(data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wAARCAAMAAsDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD84EgeSKSRVykeNxz0z0qOiiu0D//Z);
    background-repeat: repeat;
    display: flex;
    grid-template-columns: repeat(7,1fr);
    color: white;
    height: 100vh;
    overflow: hidden;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
.login-div{
    border: 2px solid #33323e;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;
    height: 400px;
    width: 344px;
    background: #181622;
    border-radius: 7px;
}
.login-div > img{
    height: 150px;
}
.login-div > input{
 
    background: transparent;
    border: 1px solid #33323e;
    font-size: 12px;
    color: white;
    width: 75%;
    margin-top: 10px;
    padding: 6px;
    height: 45px;
    border-radius: 8px;

}
.login-div > input:focus-visible{
    outline: none;
    border: 1px solid #6931a2 !important;   
}
.login-div > button{
    background: #6931a2;
    color: white;
    border: none;
    border-radius: 3px;
    padding: 7px;
    cursor: pointer;
    height: -moz-fit-content;
    height: fit-content;
    width: 75%;
    margin-top: 23px;
}
.login-div > button:hover{
    opacity: 80%;
}
.loader-small {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #6931a2;
    width: 30px;
    height: 30px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    margin-top: 25px;
  }

.toggleLang{
    position: absolute;
    left: 10px;
    top: 10px;
    display: flex;
    justify-content: space-around;
    width: 75px;
    border: 1px solid #33323e;
    border-radius: 21px;
    background: linear-gradient(to right, #6931a2 50%, transparent 50%);
    transition: background-color 2s ease;
    cursor: pointer;
    flex-direction: row-reverse;
    
}
.toggleLangToright{
    position: absolute;
    left: 10px;
    top: 10px;
    display: flex;
    justify-content: space-around;
    width: 75px;
    border: 1px solid #33323e;
    border-radius: 21px;
    transition: background-color 2s ease;
    background: linear-gradient(to left, #6931a2 50%, transparent 50%);
    cursor: pointer;
}
.toggleLang > div{
    width: 50%;

}
.toggleLangToright > div{
    width: 50%;
}

.toggleLangToright .LeftToggle{
    font-weight: bold;
}

.toggleLang .RightToggle{
    font-weight: bold;

}
.RightToggle{
    border-top-left-radius: 21px;
    border-bottom-left-radius: 21px;
    text-align: center;
}
.LeftToggle {
    text-align: center;
    border-top-right-radius: 21px;
    border-bottom-right-radius: 21px;
    
}
.none{
    display: none;
  }
  