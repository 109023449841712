tspan {
    fill: #ffffff80;
    direction: ltr;
}
line{
    stroke:#ffffff80 !important;
}
.css-13aj3tc-MuiChartsSurface-root {
    width: 269px;
    height: 275px;
    display: flex;
}
.TwoBarscoreContainerFather{
    display: flex;
}
.TwoBarscoreContainerFather >.css-uguv5e-MuiResponsiveChart-container{
    width: 100%;
    height: 252px;
    margin-top: -20px;
}