@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800&display=swap');

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    font-family: 'Cairo', sans-serif;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    margin: 0;
    padding: 0;
}

::-webkit-scrollbar {
    width: 8px;
    height: 3px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    /* border: 1px solid #b7cdec; */
    border-top: none;
    border-bottom: none;
    background: #3D2E4F;
}

::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;

}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}



.home {
    text-align: center;
    background-image: url("../public/background.jpg");
    height: 100vh;
    overflow: hidden;
    width: 100%;
    display: flex;
    color: white;
    flex-direction: row-reverse;
    

    @media (max-width:768px) {
        flex-direction: column;
        height: fit-content;
    }

    .button {
        background-color: #321D2E;
        color: white;
        width: fit-content;
        padding: 3px 8px;
        border-radius: 3px;
        cursor: pointer;
        text-decoration: none;
        border: none;
        font-size: 13px;
    }
    .button.toggle {
        background-color: transparent;
        border: 1px solid #33323e;
        background: #181622;
    }
    .button.toggle.active {
        background-color: #321D2E;
    }
    
    .button.upper{
        background: #181622;
        width: 25%;
        border: 1px solid #33323e;
        font-size: 12px;

    }
    .button.upper.active{
        background: #321D2E;
    }
    .upperButtonFather{
        display: flex;
        gap: 4px;
        width: 100%;
        right: 25px;
        margin-top: 2px;
        margin-bottom: 1px;
        flex-direction: row;
    }

    .zoom {
        height: 32px;
        width: 64px;
        position: absolute;
        color: black;
        top: 0%;
        left: 93%;
        display: flex;
        gap: 0.5rem;


        .button-zoom {
            height: 100%;
            width: 50%;
            top: 8px;
            right: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            cursor: pointer;
            color: white;
        }
    }
    .graph-container > div:first-child{
        border: 1px solid #33323e;
    }
    .graph-container {
        text-transform: uppercase;
        display: inline;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100vh;
        width: 60%;
        gap: 1rem;
        overflow: hidden;
        margin: 0.25rem;
        position: relative;


        .graph-container .graph {
            height: 50vh;
            overflow: hidden;
        }

        .graph-cont {
            height: 50%;
            display: flex;
            flex-direction: row-reverse;
            border: 1px solid #33323e;
            border-top: none;

        }

        .graph-tables {
            width: 50%;
            position: relative;
            overflow-x: scroll;
        }

        .graph-table th:nth-of-type(1) {
            text-align: left;
        }

        .graph-tables .buttons {
            display: flex;
            width: 100%;
            font-size: 10px;
            overflow: hidden;
            margin-top: 2px;
            
        }

        .graph-tables .buttons .button {
            font-weight: bold;
            letter-spacing: 1px;
            letter-spacing: 2;
            font-size: 8px;
            width: 50%;
            padding: 3px 0;
            background: transparent;
        }
        .graph-tables .buttons .button.active {
            background:#321D2E;
        }

        .graph-tables-two {
            width: 50%;
            overflow-x: scroll;
            margin: 5px;
            //padding: 2px;
            //padding-top: 6px;
            //border: 1px solid #33323e;
            margin-top:0px
        
        }

        @media (max-width:768px) {
            width: 100vw;
            height: fit-content;
            justify-content: start;


        }


        .graph-label {

            @media (max-width:768px) {
                display: none;
            }

        }

    }

    .table-container {
        width: 40%;
        height: 100%;

        select {
            padding: 3px 8px;
            border-radius: 3px;
            border: none;
            outline: none;
            width: 10%;
        }

        @media (max-width:768px) {
            width: 100%;
            display: flex;
            flex-direction: column-reverse;

        }

        .upper {
            display: flex;
            justify-content: space-between;
            width: 100%;
            font-size: 14px;
            gap: 10px;
        }

        a {
            background-color: #321D2E;
            color: white;
            padding: 3px 8px;
            border-radius: 3px;
            cursor: pointer;
            text-decoration: none;
            width: 15%;
            height: 35px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            direction: ltr;
        }

        .input {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top: 5px;
            border-radius: 3px;
            overflow: hidden;
            margin-bottom: 8px;
            gap: 3px;
        }

        .input input {
            font-size: 12px;
            padding: 3px 0;
            padding-right: 3px;
            padding-left: 3px;
            border: none;
            width: 100%;
            height: 100%;
            outline: none;
        }

        .input input:focus::placeholder {
            opacity: 0;
            transition: .3s;
        }

        .input .submit {
            padding: 0 8px;
            cursor: pointer;
            outline: none;
            border: none;
            color: white;
            font-size: 15px;
            background-color: rgb(224, 107, 107);
            width: 50%;

        }

        .score-indicator {
            display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 10px 0; */
    /* width: fit-content; */
    max-width: 186px;
    /* padding-left: 20px; */
    height: 100%;
    margin-top: 3%;
        }
        

        .score-indicator>div:nth-child(2) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 80%;
        }

        .score-indicator>div:nth-child(3) {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4px;
            color: rgb(182, 182, 182);
        }

        .score-indicator>div>div {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .score-indicator h3 {
            font-size: 20px;
        }

        .score-indicator h5 {
            font-size: 13px;
            color: rgb(182, 182, 182);
        }

        .table-content {
            // padding: 1rem 0.25rem;
            height: 40%;
            overflow-y: scroll;


            h2 {
                width: 100%;
                text-align: start;
                padding: 0 1rem;
            }

        }

    }

    tr {
        font-size: 11px;
    }

    td {
        width: calc(100%/4);
    }

    thead {
        font-size: 10px;
    }


}

// Model css

.MuiBox-root {
    background-color: #161523;
    color: white;
}
.centerLink{
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.select__input {
    color: black !important;
}
.height .select__input{
    color: white !important;
}