#root{
  height: 100%;
  width: 100%;
  direction: rtl;
}
.dashboard{
  background-image: url("../../public/background.jpg");
    background-repeat: repeat;
  display: flex;
  grid-template-columns: repeat(7,1fr);
  color: white;
  height: 100vh;
  overflow: hidden;
  align-content: center;
  align-items: center;
  padding: 10px;
  flex-direction: row;
  
  width: 100%;

}
.dashboard-right{
  padding: 4px;
  /* border-radius: 3px; */
  font-size: 12px;
  /* max-height: 51vh; */
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0px;
  /* border-radius: 7px; */
  display: flex;
  flex-direction: column;
  width: 210px;
  height: 85%;
  justify-content: center;

}


.dashboard-head{
  min-height: 7%;
  /* background: #3c5475; */
  margin-bottom: 4px;
  /* color: #bf93cb; */
  background: #3D2E4F;
  /* border: 2px solid #6931a2; */
  text-align: center;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  width: 240px;
}
.dashboard-option{
  color: white;
  /* text-align: center; */
  text-decoration: none;
  padding: 3px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  background-color: #181622;
  display: flex;
  margin-bottom: 3px;
  border: 1px solid #33323e;
  justify-content: center;
  margin: 6px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 14px;
  cursor: pointer;
}
.active{
  background: #6931a2;
}
.dashboard-principal{


/* border-radius: 3px; */

height: 85%;
margin-top: 10px;
margin-bottom: 10px;
padding: 0px;
/* border-radius: 7px; */
display: flex;
flex-direction: column;
margin-right: 10px;
padding: 5px;
width: 100%;
overflow-y: scroll;
flex-direction: row;


}
.dashboard-principal.solo{
  justify-content: center;
}
.dashboard-list{
padding: 4px;
  /* border-radius: 3px; */
  background-color: #181622;
  font-size: 12px;
  border: 1px solid #33323e;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px;
  /* border-radius: 7px; */
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  padding: 5px;
  width: 66%;
}
.dashboard-qyanat{
padding: 4px;
border-radius: 10px;
/* border-radius: 3px; */
background-color: #181622;
font-size: 12px;
border: 1px solid #33323e;
margin-top: 0px;
margin-bottom: 0px;
padding: 0px;
/* border-radius: 7px; */
display: flex;
flex-direction: column;
margin-right: 10px;
padding: 5px;
width: 30%;
overflow-y: scroll;
}
.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.fade-in.show {
  opacity: 1;
}
.table-colum-full{
  background-color: #6931a2;
  width: 100%;
  height: 37px;
  font-size: 14px;
  align-items: center;
  margin-top: 10px;


}
.table-colum{
  display: flex;
  width: 100%;
  /* border: 1px solid #33323e; */
  height: 37px;
  font-size: 14px;
  align-items: center;
  margin-top: 10px;;
}
.table-colum-head{
  display: flex;
  width: 100%;
  border: 1px solid #33323e; 
  height: 37px;
  font-size: 14px;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}
.principal-colum{
  width: 90%;
  padding-right: 5px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

}
.edit-colum{
  width: 5%;
}
.delet-colum{
  height: 100%;
  width: 8%;
  display: flex;
  background: #321D2E;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.search-button{
font-size: 15px;
padding: 5px;
padding-right: 10px;
padding-left: 10px;
border: 1px solid #33323e;
background: #27143A;
cursor: pointer;
}
.search-input{
background: #1C1A28;
border: none;
color: white;
border: 1px solid #33323e;
border-left: none;
padding: 4px;
direction: rtl;
text-align: right;
}
.form-search{
display: flex;
justify-content: center;
border-bottom: 1px solid gray;
padding-bottom: 10px;
}
.form-search2{
display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: 1px solid gray;
}
.form-search2 >*{
border: none !important;
}
.search-button.wide{
text-align: center;
width: 200px;
display: flex;
justify-content: space-around;
align-items: center;
cursor: pointer;
}
.modal-container{
background: #161523;
border: none;
color: white;
border: 1px solid #33323e;
height: 650px;
width: 450px;
display: flex;
flex-direction: column;
align-items: center;
padding: 15px;
border-radius: 7px;
min-width: fit-content;
}
.modal-parent{
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
overflow-y: scroll; 
direction: rtl;
}
.Confirmation-button{
background: #6A30A5;
padding: 5px;
padding-right: 25px;
/* font-size: 16px; */
margin-top: 10px;
padding-left: 25px;
border-radius: 6px;
cursor: pointer;
}
.Back-button{
border: 1px solid #33323e;
padding: 5px;
padding-right: 25px;
/* font-size: 16px; */
margin-top: 10px;
padding-left: 25px;
border-radius: 6px;
cursor: pointer;
background: #1C1A28;
}
.Delet-button{
border: 1px solid #33323e;
padding: 5px;
padding-right: 40px;
/* font-size: 16px; */
margin-top: 15px;
padding-left: 40px;
border-radius: 6px;
cursor: pointer;
color:white;
background: #b62d2b;
}
.add-keywoard{
display: flex;
justify-content: center;
margin-top: 10px;
}
.add-keywoard > input{
width: 50%;
border-left: none;
border-bottom-right-radius: 7px;
border-top-right-radius: 7px;
}
.add-keywoard > div{
padding-right: 25px;
border-right: none;
padding-left: 25px;
border-radius: 7px;
border-bottom-right-radius: 0px;
border-top-right-radius: 0px;
cursor: pointer;
}
input:focus-visible{
outline:none;
}
.dashboard-list > ul{
  direction: ltr;
  margin-bottom: 0px;
  padding-top: 5px;
  border-top: 1px solid gray;

}
.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: #6c757d !important;
  --bs-pagination-bg: #27143A !important;
  --bs-pagination-border-width: 1px !important;
  --bs-pagination-border-color: #27143A !important;
  --bs-pagination-border-radius: 0.375rem !important;
  --bs-pagination-hover-color: #6931a2 !important;
  /* --bs-pagination-ho: #27143A; */
  --bs-pagination-hover-bg: #27143A !important;
  --bs-pagination-hover-border-color: #27143A !important;
  --bs-pagination-focus-color: #6931a2 !important;
  --bs-pagination-focus-bg: #e9ecef !important;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
  --bs-pagination-active-color: white !important;
  --bs-pagination-active-bg: #6931a2 !important;
  --bs-pagination-active-border-color: #6931a2 !important;
  --bs-pagination-disabled-color: #6c757d !important;
  --bs-pagination-disabled-bg: #27143A !important;
  --bs-pagination-disabled-border-color: #27143A !important;

}
.dashboard-list.user{
  width: 100%;
}
.table-colum-head.user{
  border-radius: 7px;
  height: 100%;
  
}
.principal-colum.user{
  background: #1C1A28;
  
}
 
.two-input {
    display: flex;
    margin-bottom: 25px;
    gap: 20px;
}
.two-input div{
  display: flex;
  flex-direction: column;
}
.one-input {
  margin-bottom: 25px;
}
.one-input .passwoard {
  margin-bottom: 25px;
}
.one-input > .label > div {
  margin-left: 25px;
}
.modal-inpute{
  background: #1C1A28;
  border: none;
  color: white;
  border: 1px solid #33323e;
  padding: 4px;
  direction: rtl;
  text-align: right;
}
.activeTab{
  background-color: #6A30A5;
}
.three-input{
  display: flex;
    margin-top: 20px;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
}
.api{
  font-size: 15px;
  align-items: center;
  display: flex;
  align-content: center;
  margin: 0px;
  margin-left: 10px;
  margin-right: 15px;
}