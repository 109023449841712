$color-black: hsl(0, 0%, 5%);
$color-color: hsl(0, 0%, 100%);



$color-4: hsl(222, 100%, 54%);
$color-3 : hsl(180, 82%, 43%);
$color-2 :  hsl(43, 93%, 54%);
$color-1: hsl(265, 64%, 50%);


$porcent-html: 90;

@function strokeDashoffset($num) {
	@return 440 - (440 * $num) / 100;
}

* {
	box-sizing: border-box;
}

.container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.container__progressbars {
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-wrap: wrap;
	
}

.progressbar {
    position: relative;
    width: 118px;
    height: 131px ;
    transform: rotate(-90deg);
}

.progressbar__svg {
	position: relative;
	width: 100%;
	height: 100%;
    transform: rotate(167deg);
}

.progressbar__svg-circle {
	width: 100%;
	height: 100%;
	fill: none;
	stroke-width: 4.6;
	stroke-dasharray: 440;
	stroke-dashoffset: 440;
	stroke: hsl(0, 0%, 100%);
	stroke-linecap: round;
  
	transform: translate(5px, 5px); // stroke-width / 2
}
.progressbar__svg-circle.four {
    stroke: hsl(222, 100%, 54%);
}
.three {
    stroke: hsl(180, 82%, 43%);
}
.two {
    stroke: $color-2;
}
.one {
    stroke:$color-1;
}


.shadow {
	&-1 {
		filter: drop-shadow(0 0 1px $color-1);
	}
	&-2 {
		filter: drop-shadow(0 0 1px $color-2);
	}
	&-3 {
		filter: drop-shadow(0 0 1px $color-3);
	}
	&-4 {
		filter: drop-shadow(0 0 1px $color-4);
	}
}

.circle {
	&-html {
		animation: dashOffsetAnimation 1s ease-in-out forwards;
	}
	
}

.progressbar__text {
	position: absolute;
	top: 50%;
	left: 50%;
	padding: 0.25em 0.5em;
    font-size: 8px;
	color: hsl(0, 0%, 100%);
	font-family: Arial, Helvetica, sans-serif;
	border-radius: 0.25em;
	transform: translate(-50%, -50%) rotate(90deg);
    min-width: 32.017px;
    min-height: 17.9972px;
    text-align: center;
}
.progressbar__text.fourth {
    transform: rotate(49deg);
    top: 54%;
    left: -4%;
    font-size: 9px;
}
.progressbar__text.three {
    transform: rotate(49deg);
    top: 53%;
    left: 5%;
    font-size: 9px;
}
.progressbar__text.two {
    transform: rotate(49deg);
    top: 51%;
    left: 13%;
    font-size: 9px;
}
.progressbar__text.one {
    transform: rotate(49deg);
    top: 48%;
    left: 20%;
    font-size: 9px;
}
.progressbar__text.center{
    font-weight: bold;
    font-size: 13px;
    top: 43%;
    left: 55px;
    
}
//  @keyframes
.animated-circle {
    stroke: #3498db; /* Adjust the color as needed */
    fill: none;
    stroke-width: 10; /* Adjust the stroke width as needed */
  }
  
@keyframes dashOffsetAnimation {
    to {
      stroke-dashoffset: 0;
    }
  }
@keyframes anim_circle-html {
	to {
		stroke-dashoffset: strokeDashoffset($porcent-html);
	}



}
.typeCercle{
	transform: rotate(90deg);
    position: absolute;
    top: -10px;
    right: 7px;
}